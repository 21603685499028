import {apiService} from "./ApiService";

class LeadApiRequests {
  async getleads(params) {
    const res = await apiService.sendClientRequest("get", "leads", params, {
      headers: {
        "Cache-Control": "no-cache",
      },
    });
    
    return res
  }

  getLeadStatuses(params) {
    return apiService.sendClientRequest("get", "lead_statuses", params, {});
  }
  getClientCategories() {
    return apiService.sendClientRequest("get", "task_categories");
  }
  updateClientsLeadStatus(status_id, lead_id) {
    let update_object = {
      action: "edit_lead_status",
      lead_status_id: status_id,
    };
    return apiService.sendClientRequest(
      "put",
      "leads/" + lead_id,
      update_object
    );
  }

  async getEarlyRenewalsData({ plan_id } = {}) {
    const res = await apiService.sendClientRequest(
      "get",
      "charges/recurring/info",
      plan_id && { plan_id }
    );
    return res.data.response.return_data;
  }

  async postEarlyRenewalsData(data = {}) {
    const payload = {
      is_early_renewal: 1,
    };

    if (data && data.plan_id) {
      payload.plan_id = data.plan_id;
    }

    await apiService.sendClientRequest("post", "charges/recurring", payload, {
      msgStyle: "old",
    });
  }

  async sendTestLead(options = {}) {
    const res = await apiService.sendClientRequest("post", "send_test_lead", {}, options);
    const lead_id = res.data.response.return_data.id;
    return lead_id;
  }

  async getLeadsWithoutContactAmount() {
    const res = await apiService.request("get", "leads", { only_roi: 1 }, {}, "v3");
    return res;
  }
}




export const leadApi = new LeadApiRequests();
