import React from 'react';
import styled, { css } from 'styled-components';

import { ICONS } from "../../../general/assetsUtils";

/**
 * if the title is longer than this, we align elements to the top
 */
const MAX_ONE_LINE_LENGTH = 40;
export default function Toast({ variant, title, description, onClose }) {
  // set the defaults
  variant = variant || "no-icon";

  const withDescription = !!description;
  const withLongTitle = title.length > MAX_ONE_LINE_LENGTH;
  const alignStart = withDescription || withLongTitle;

  return (
    <StyledToast alignStart={alignStart}>
      {variant !== "no-icon" && (
        <StyledIconContainer variant={variant}>
          {variant === "success" && <StyledInnerIconContainer>{ICONS.TOASTS.CHECK}</StyledInnerIconContainer>}
          {variant === "warn" && <StyledInnerIconContainer>{ICONS.TOASTS.WARN}</StyledInnerIconContainer>}
          {variant === "error" && <StyledInnerIconContainer>{ICONS.TOASTS.ERROR}</StyledInnerIconContainer>}
          <span className="sr-only">Toast icon</span>
        </StyledIconContainer>
      )}
      <StyledContentContainer data-testid='toast-msg'>
        <StyledTitle>{title}</StyledTitle>
        {withDescription && <StyledDescription>{description}</StyledDescription>}
      </StyledContentContainer>
      {onClose && (
        <StyledCloseButton onClick={onClose} aria-label="Close">
          <StyledInnerCloseIconContainer>
            {ICONS.TOASTS.CLOSE}
          </StyledInnerCloseIconContainer>
        </StyledCloseButton>
      )}
    </StyledToast>
  );
}

const StyledToast = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 1rem;
  color: #718096;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  ${(props) => props.alignStart && css`
    align-items: flex-start;
  `}
`;

const StyledIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;

  ${(props) => props.variant === 'success' && css`
    color: #48bb78;
    background-color: #f0fff4;
  `}

  ${(props) => props.variant === 'error' && css`
    color: #f56565;
    background-color: #fff5f5;
  `}

  ${(props) => props.variant === 'warn' && css`
    color: #ed8936;
    background-color: #fffaf0;
  `}
`;

const StyledInnerCloseIconContainer = styled.div`
  height: 0.8rem;
  width: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInnerIconContainer = styled.div`
  height: 1.3rem;
  width: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledTitle = styled.div`
  margin-left: 0.75rem;
  font-size: 0.875rem;
  font-weight: bold;
`;

const StyledDescription = styled.div`
  margin-left: 0.75rem;
  font-size: 0.875rem;
  font-weight: normal;
  color: #a0aec0;
`;

const StyledCloseButton = styled.button`
  margin-left: auto;
  display: inline-flex;
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 0.375rem;
  color: #a0aec0;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #edf2f7;
    color: #2d3748;
  }
`;
