import React from "react";
import styled, { css } from "styled-components";

import { theme } from "../../../accounts/sharedComponents/theme";
import Toast from "../Toast";
import useToastStore from "../ToastStore";

export default function ToastTray() {
  const toasts = useToastStore((state) => state.toasts);
  const removeToast = useToastStore((state) => state.remove);

  return (
    <StyledToastTray isEmpty={toasts.length === 0}>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          variant={toast.variant}
          title={toast.title}
          description={toast.description}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </StyledToastTray>
  );
}

const StyledToastTray = styled.div`
  position: fixed;
  z-index: 9999999999;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 24rem;
  max-height: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    /* Hide scrollbar for WebKit browsers */
    display: none;
  }

  ${(props) =>
    !props.isEmpty &&
    css`
      padding: 1rem;
    `}

  ${theme.media.tablet} {
    top: 4rem;
  }
`;
