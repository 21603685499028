import {resetPage, updateClaimStatuses, updateStatuses} from "../slices/leadsSlice.js";
import {leadApi} from "../../restApi/LeadApi.js";
import {createAsyncThunk} from '@reduxjs/toolkit';

export const setPage = (searchParams, page = "all") => {
    const params = {...searchParams};
    if (page === "all") {
        params.rows_per_page *= params.page;
        params.page = 1;
    } else {
        params.page = page;
    }
    return params;
}

export const getLeads = createAsyncThunk('leads/getLeads',
    async (currentPage, {getState, dispatch, signal}) => {
        const {leads} = getState();
        const searchParams = !!currentPage ? setPage(leads.searchParams, currentPage) : leads.searchParams;
        const response = await leadApi.getleads(searchParams, signal);
        const data = response.data.data;
        const values = Object.values(data);
        if(values.length){
            dispatch(updateClaimStatuses(values[0].lead_claim_statuses));
            values.forEach((lead, index) => {
                lead.page = currentPage === "all"
                ? Math.ceil((index + 1) / searchParams.rows_per_page)
                : response.data.pager.page;
                
            });
        }
        return response;
});

export const getLeadStatuses = createAsyncThunk('leads/getLeadStatuses',
    async (_, {getState, dispatch}) => {
        const {leads} = getState();
        const searchParams = leads.searchParams;
        const response = await leadApi.getLeadStatuses(searchParams);
        const data = response.data.data;
        const values = Object.values(data);
        if(values.length){
            dispatch(updateStatuses(values));
        }
        return response;
    });

export const getLeadsAndStatuses = createAsyncThunk('leads/getLeadsAndStatuses',
    (_, {dispatch}) => {
        dispatch(resetPage());
        dispatch(getLeads());
        dispatch(getLeadStatuses());
    });
