const ASSETS_PATH = '/resources/images/accounts/';
const IMAGES_PATH = ASSETS_PATH + '';
const ICONS_PATH = IMAGES_PATH + '';

export const ICONS = {
  GENERAL: {
    LOGO: iconElement('networx-logo', 'Networx icon', 'icon-networx'),

    CHECK: iconElement('check', 'Check icon', 'icon-check'),
    DISCOUNT: iconElement('discount', 'Discount icon', 'icon-discount'),
    SEARCH: iconElement('search', 'Search icon', 'icon-search'),
    FILTER: iconElement('filter', 'Filter icon', 'icon-filter'),
    EXPORT: iconElement('export', 'Export icon', 'icon-export'),
    CLOSE: iconElement('close', 'Close icon', 'icon-close'),
    NEW_MESSAGES: iconElement('new-message', 'New Message icon', 'icon-new-message'),
    NEW_MESSAGES_COLOURED: iconElement(
      'new-message-coloured',
      'New Message Colored icon',
      'icon-new-message-coloured',
      'newMessages',
    ),
    TRANSFERRED: iconElement('transferred', 'Transferred icon', 'icon-transferred'),
    APPROVED: iconElement('approved', 'Approved icon', 'icon-approved'),
    DENIED: iconElement('denied', 'Denied icon', 'icon-denied'),
    REPORTED: iconElement('reported', 'Reported icon', 'icon-reported'),
    CONVERSATION: iconElement('conversation', 'Conversation icon', 'icon-conversation'),
    BACK: iconElement('back', 'Back arrow icon', 'icon-back'),
    CLOCK: iconElement('clock', 'Clock icon', 'icon-clock'),
    SHARE: iconElement('share', 'Share icon', 'icon-share'),
    FRIEND_GET: iconElement('friend-get', 'Friend get icon', 'icon-friend-get'),
    YOU_GET: iconElement('you-get', 'You get icon', 'icon-you-get'),
    COPY: iconElement('copy', 'Copy icon', 'icon-copy'),
    SEND: iconElement('send', 'Send icon', 'icon-send'),
    SEND_OUTLINED: iconElement('send_outlined', 'Send outlined icon', 'icon-send-outlined'),
    MY_REFERRALS: iconElement('my-referrals', 'My referrals icon', 'icon-my-referrals'),
    //SIGNUP NEW
    SIGNUP_ARROW: iconElement('signup/arrow', 'Arrow icon', 'icon-arrow'),
    CHECKMARK: iconElement('checkmark', 'Checkmark icon', 'icon-checkmark'),
    ZIP: iconElement('signup/zip', 'Zip icon', 'icon-zip'),
    SUCCESS: iconElement('success', 'success icon', 'icon-success'),
    WARNING: iconElement('signup/warning', 'Warning icon', 'icon-warning'),
    CANCEL_PLAN: iconElement('signup/cancel', 'Cancel icon', 'icon-indicators'),
    SETUP_FEE: iconElement('signup/setup-fee', 'Setup fee icon', 'icon-indicators'),
    NO_COMMITMENTS: iconElement('signup/no-commitments', 'No commitments icon', 'icon-indicators'),
    LOCK: iconElement('signup/lock-icon', 'Lock icon', 'icon-encryption'),
    SPINNER: iconElement('spinner', 'Spinner icon', 'icon-spinner'),

    //Dashboard icons
    BUSINESS_PROFILE: iconElement('business-profile', 'Business profile icon', 'business-profile'),
    RATING: iconElement('rating', 'Rating icon', 'rating'),
    YOU: iconElement('you', 'You icon', 'you'),
    AVG_PRO: iconElement('avg-pro', 'Average pro icon', 'avg-pro'),
    FINISH: iconElement('finish', 'Finish icon', 'icon-finish'),
    TODO_LIST: iconElement('dashboard/todo_list', 'Todo list icon', 'icon-todo-list'),
    GROW_BAR: iconElement('dashboard/grow_bar', 'Grow bar icon', 'icon-grow-bar'),
    PAUSE: iconElement('dashboard/pause', 'Pause icon', 'icon-pause'),
    LIGHT: iconElement('dashboard/light', 'Light icon', 'icon-light'),
    SMALL_CALENDAR: iconElement(
      'dashboard/small_calendar',
      'Small calendar icon',
      'icon-small-calendar',
    ),
    ADD: iconElement('add', 'Add icon', 'add'),
    COMPLETE: iconElement('complete', 'Complete icon', 'complete'),
    ARROW: iconElement('arrow', 'Arrow icon', 'arrow'),
    STAR: iconElement('star', 'Star icon', 'icon-star'),

    //Cancel request
    LIGHT_BULB: iconElement('lightbulb', 'light bulb icon', 'light_bulb'),
    CONFIRMATION: iconElement('dashboard/confirmation', 'Confirmation icon', 'icon-confirmation'),

    //Thumbtack icons
    USER: iconElement('thumbtack/user', 'user icon', 'user'),
    HIRED: iconElement('thumbtack/hired', 'hired icon', 'hired'),
    TOP_PRO: iconElement('thumbtack/toppro', 'toppro icon', 'toppro'),
    LOCATION: iconElement('thumbtack/location', 'location icon', 'location'),

    //Loyalty program
    PRO: iconElement('loyalty/pro', 'Pro icon', 'icon-pro'),
    CHAMPION: iconElement('loyalty/champion', 'Champion icon', 'icon-champion'),
    CHAMPION_POPUP: iconElement(
      'loyalty/champion_popup',
      'Champion_popup icon',
      'icon-champion_popup',
    ),
    HERO: iconElement('loyalty/hero', 'Hero icon', 'icon-hero'),
    HERO_POPUP: iconElement('loyalty/hero_popup', 'Hero_popup icon', 'icon-hero_popup'),
    LOYALTY_POPUP: iconElement('loyalty/loyalty_popup', 'Loyalty_popup icon', 'icon-loyalty_popup'),
    CHECK_BENEFIT: iconElement('loyalty/check', 'Check icon', 'icon-check'),
    TIP: iconElement('loyalty/tip', 'Tip icon', 'icon-tip'),
    TIP_ACTIVE: iconElement('loyalty/tip-active', 'Tip active icon', 'icon-tip-active'),
    INFO: iconElement('loyalty/info', 'Info icon', 'icon-info'),
    //OPPORTUNITIES
    INFO_CIRCLE: iconElement('info-circle', 'Info circle icon', 'icon-info-circle'),
    INFO_CIRCLE_THIN: iconElement(
      'info-circle-thin',
      'Info circle thin icon',
      'icon-info-circle-thin',
    ),
    TARGET: iconElement('target', 'Target icon', 'icon-target'),
    DOLLAR: iconElement('dollar-circle', 'Dollar icon', 'icon-dollar'),
    DOLLAR_FILL: iconElement('dollar-circle-fill', 'Dollar fill icon', 'icon-dollar-fill'),
    LOCATION_PIN: iconElement('location-pin', 'Location pin icon', 'icon-location-pin'),
    CHECKMARK_CIRCLE: iconElement(
      'checkmark-circle',
      'Checkmark circle icon',
      'icon-checkmark-circle',
    ),
    QUESTION_MARK: iconElement('question-mark', 'Question mark icon', 'icon-question-mark'),
    QUESTION_MARK_BLUE: iconElement(
      'question-mark-blue',
      'Question mark blue icon',
      'icon-question-mark-blue',
    ),
    HOUSE: iconElement('house', 'House icon', 'icon-house'),
    NOT_AVAILABLE: iconElement(
      'not-interested/not-available',
      'Not available icon',
      'icon-not-available',
    ),
    TOO_FAR_AWAY: iconElement(
      'not-interested/too-far-away',
      'Too far away icon',
      'icon-too-far-away',
    ),
    NOT_SUITABLE: iconElement(
      'not-interested/not-suitable',
      'Not suitable icon',
      'icon-not-suitable',
    ),
    NOT_ENOUGH_INFO: iconElement(
      'not-interested/not-enough-info',
      'Not enough info icon',
      'icon-not-enough-info',
    ),
    OUTSIDE_BUDGET: iconElement(
      'not-interested/outside-budget',
      'Outside budget icon',
      'icon-outside-budget',
    ),
    LOCATION_PIN_NO_FILL: iconElement(
      'location-pin-no-fill',
      'Location pin no fill',
      'location-pin-no-fill',
    ),

    //Billing
    WARNING_TRIANGLE: iconElement('warning', 'Warning icon', 'icon-warning'),
    CHECK_CIRCLE_GRAY: iconElement(
      'check-circle-gray',
      'Check circle gray icon',
      'icon-check-circle-gray',
    ),
    RIGHT_GREY_ARROW: iconElement(
      'right-grey-arrow',
      'Right grey arrow icon',
      'icon-right-grey-arrow',
    ),
    RED_TRASH_CAN: iconElement('red-trash-can', 'Red trash can icon', 'icon-red-trash-can'),

    //Plans
    CLOSE_CIRCLE: iconElement('close-circle', 'Close circle icon', 'icon-close-circle'),
    PLUS_BLUE: iconElement('plus-blue', 'Plus blue icon', 'icon-plus-blue'),
  },
  SOCIAL: {
    MESSENGER: iconElement('messenger', 'Messenger icon', 'icon-messenger'),
    FACEBOOK: iconElement('facebook', 'facebook icon', 'icon-facebook'),
    TWITTER: iconElement('twitter', 'Twitter icon', 'icon-twitter'),
  },
  SETTINGS: {
    CONFIRM: iconElement('settings/confirm', 'Confirm icon', 'icon-confirm'),
  },
  LEADS: {
    PERSON: iconElement('leads/person', 'Person icon', 'icon-person'),
  },
  LEAD: {
    MESSAGE: iconElement('lead/message', 'message icon', 'icon-message'),
    NEW_MESSAGE: iconElement('lead/new-message', 'new-message icon', 'icon-new-message'),
    STAR: iconElement('lead/star', 'star icon', 'icon-star'),
    REPLIED: iconElement('lead/replied', 'replied icon', 'icon-replied'),
    EMAIL: iconElement('lead/email', 'email icon', 'icon-email'),
    MAP: iconElement('lead/map', 'map icon', 'icon-map'),
    PHONE: iconElement('lead/phone', 'phone icon', 'icon-phone'),
    USER: iconElement('lead/user', 'user icon', 'icon-user'),
    COST: iconElement('lead/cost', 'cost icon', 'icon-cost'),
    DESCRIPTION: iconElement('lead/description', 'description icon', 'icon-description'),
    LOCATION: iconElement('lead/location', 'location icon', 'icon-location'),
    DATE: iconElement('lead/date', 'date icon', 'icon-date'),
    PLUS: iconElement('lead/plus', 'plus icon', 'icon-plus'),
    CHECKMARK: iconElement('lead/checkmark', 'checkmark icon', 'icon-checkmark'),
    ARROW: iconElement('lead/arrow', 'arrow icon', 'icon-arrow'),
    INFO: iconElement('lead/info', 'info icon', 'icon-info'),
    EDIT: iconElement('lead/edit', 'edit icon', 'icon-edit'),
    DELETE: iconElement('lead/delete', 'delete icon', 'icon-delete'),
    QUESTION: iconElement('lead/question', 'question icon', 'icon-question'),
  },
  DASHBOARD: {
    EXIT: iconElement('dashboard/exit', 'Exit icon', 'icon-exit'),
    DOLLAR: iconElement('dashboard/dollar', 'Dollar icon', 'icon-dollar'),
    NOTES: iconElement('dashboard/notes', 'Notes icon', 'icon-notes'),
  },
  ONBOARDING: {
    CHECK: iconElement('onboarding/check', 'Check icon', 'icon-check'),
    SETTINGS_ACTIVE: iconElement(
      'onboarding/settings-active',
      'Settings active icon',
      'icon-settings-active',
    ),
    SETTINGS_INACTIVE: iconElement(
      'onboarding/settings-inactive',
      'Settings inactive icon',
      'icon-settings-inactive',
    ),
    PROFILE_ACTIVE: iconElement(
      'onboarding/profile-active',
      'Profile active icon',
      'icon-profile-active',
    ),
    PROFILE_INACTIVE: iconElement(
      'onboarding/profile-inactive',
      'Profile inactive icon',
      'icon-profile-inactive',
    ),
    SEND_LEAD_ACTIVE: iconElement(
      'onboarding/lead-test-active',
      'Send lead active icon',
      'icon-lead-test-active',
    ),
    SEND_LEAD_INACTIVE: iconElement(
      'onboarding/lead-test-inactive',
      'Send lead inactive icon',
      'icon-send-lead-inactive',
    ),
    BOOK_ACTIVE: iconElement('onboarding/book-active', 'Book active icon', 'icon-book-active'),
  },
  SIGNUP: {
    ROI: iconElement('signup/roi', 'Roi icon', 'icon-roi'),
    PAYMENT: {
      CHECK: iconElement('signup/payment/check', 'Check icon', 'icon-check'),
      LIST: iconElement('signup/payment/list', 'List icon', 'icon-list'),
      ALERT: iconElement('signup/payment/alert', 'Alert icon', 'icon-alert'),
      DOCUMENT: iconElement('signup/payment/document', 'Document icon', 'icon-document'),
      SHIELD: iconElement('signup/payment/shield', 'Shield icon', 'icon-shield'),
      CREDIT: iconElement('signup/payment/credit', 'Credit icon', 'icon-credit'),
    },
    CHECKMARK: iconElement('signup/checkmark', 'checkmark icon', 'icon-checkmark'),
    DOLLAR: iconElement('signup/dollar', 'dollar icon', 'icon-dollar'),
    DOUBLE_STARS: iconElement('signup/double-stars', 'double-stars icon', 'icon-double-stars'),
    TRUSTPILOT_STARS: iconElement(
      'signup/trustpilot-stars',
      'trustpilot-stars icon',
      'icon-trustpilot-stars',
    ),
    TRUSTPILOT_LOGO: iconElement(
      'signup/trustpilot-logo',
      'trustpilot-logo icon',
      'icon-trustpilot-logo',
    ),
  },
  PAUSE: {
    SPEED_UP: iconElement('pause/speed-up', 'speed-up icon', 'icon-speed-up'),
    SLOW_DOWN: iconElement('pause/slow-down', 'slow-down icon', 'icon-slow-down'),
    CHECKED: iconElement('pause/checked', 'checked icon', 'icon-checked'),
    UNCHECKED: iconElement('pause/unchecked', 'unchecked icon', 'icon-unchecked'),
    NOT_CONNECTED: iconElement('pause/not-connected', 'not-connected icon', 'icon-not-connected'),
    HIGHER_PRICE: iconElement('pause/higher-price', 'higher-price icon', 'icon-higher-price'),
    UPDATED_PLAN: iconElement('pause/updated-plan', 'updated-plan icon', 'icon-updated-plan'),
  },
  PROFILE: {
    ADD_IMAGE: iconElement('profile/add-image', 'Add image icon', 'icon-add-image'),
    EDIT: iconElement('profile/edit', 'Edit icon', 'icon-edit'),
    IMAGE_EMPTY: iconElement('profile/image-empty', 'Image empty icon', 'icon-image-empty'),
  },
  INVOICES: {
    DOWNLOAD: iconElement('invoices', 'invoices icon', 'icon-invoices'),
    NO_RESULTS: iconElement('no-results', 'no-results icon', 'icon-no-results'),
    FILTER: iconElement('filter-invoices', 'filter-invoices icon', 'icon-filter-invoices'),
  },
  PLANS: {
    AROUND: iconElement('plans/around', 'around icon', 'icon-around'),
    BUDGET: iconElement('plans/budget', 'budget icon', 'icon-budget'),
    CARD: iconElement('plans/card', 'card icon', 'icon-card'),
    CHECK: iconElement('plans/check', 'check icon', 'icon-check'),
    CATEGORIES: iconElement('plans/categories', 'categories icon', 'icon-categories'),
    VIEW: iconElement('plans/view', 'view icon', 'icon-view'),
    PAUSED: iconElement('plans/paused', 'paused icon', 'icon-paused'),
    LOW_BALANCE: iconElement('plans/low-balance', 'low-balance icon', 'icon-low-balance'),
    PENDING: iconElement('plans/pending', 'pending icon', 'icon-pending'),
    DEACTIVATED: iconElement('plans/deactivated', 'deactivated icon', 'icon-deactivated'),
    WARNING: iconElement('plans/warning', 'warning icon', 'icon-warning'),
    SPINNER: iconElement('plans/spinner', 'plans icon', 'icon-plans'),
    KEYWORD: iconElement('plans/keyword', 'keyword icon', 'icon-keyword'),
    SERVICE: iconElement('plans/service', 'service icon', 'icon-service'),
  },
  PLAN: {
    EDIT: iconElement('lead/edit', 'edit icon', 'icon-edit'),
    PHONE: iconElement('lead/phone', 'phone icon', 'icon-phone'),
    EMAIL: iconElement('lead/email', 'email icon', 'icon-email'),
  },
  INTEGRATION: {
    DELETE: iconElement('delete', 'delete icon', 'icon-delete'),
  },
  SMS: {
    MESSAGES: iconElement('sms/messages', 'messages icon', 'icon-messages'),
    SEND_SMALL_ICON: iconElement(
      'sms/send-small-icon',
      'send-small-icon icon',
      'icon-send-small-icon',
    ),
    BOLT: iconElement('sms/bolt', 'bolt icon', 'icon-bolt'),
    SMILEY: iconElement('sms/smiley', 'smiley icon', 'icon-smiley'),
  },
  REPORTING: {
    RANGE: iconElement('reporting/range', 'range icon', 'icon-range'),
    ARROW: iconElement('reporting/arrow', 'arrow icon', 'icon-arrow'),
    SPINNER: iconElement('reporting/spinner', 'spinner icon', 'icon-spinner'),
  },
  TOASTS: {
    CHECK: iconElement('toasts/check', 'check', 'icon-toast-check'),
    CLOSE: iconElement('toasts/close', 'close', 'icon-toast-close'),
    ERROR: iconElement('toasts/error', 'error', 'icon-toast-error'),
    WARN: iconElement('toasts/warn', 'warn', 'icon-toast-warn'),
  },
};

export const IMAGES = {
  GENERAL: {
    LOGO_WHITE: imageElement(png('general/logo-white'), 'Networx logo', 'logo-networx white'),
    EMPLOYEES: imageElement(png('employees'), 'Employees', 'icon-employees'),
    //SIGNUP NEW
    LOGO_WHITE_NEW: imageElement(svg('logo'), 'Networx logo', 'logo-networx-white'),
  },
};

function svg(name) {
  return name + '.svg';
}

function png(name) {
  return name + '.png';
}

function icon(name) {
  return svg(ICONS_PATH + name);
}

function image(path) {
  return IMAGES_PATH + path;
}

function element(src, alt, className = '', id = '') {
  return (
    <img
      src={src}
      alt={alt}
      className={className || ''}
      id={id}
    />
  );
}

function iconElement(path, alt, className = '', id = '') {
  return element(icon(path), alt, className, id);
}

function imageElement(path, alt, className = '') {
  return element(image(path), alt, className);
}
