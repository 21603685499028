export const colors = {
  background: '#FFFFFF',
  foreground: {
    DEFAULT: 'hsl(0, 0%, 0%)',
    50: 'hsl(0, 0%,  0%)',
    100: 'hsl(0, 0%, 0%)',
    200: 'hsl(0, 0%, 0%)',
    300: 'hsl(0, 0%, 0%)',
    400: 'hsl(0, 0%, 0%)',
    500: 'hsl(0, 0%, 0%)',
    600: 'hsl(0, 0%, 0%)',
    700: 'hsl(0, 0%, 100%)',
    800: 'hsl(0, 0%, 100%)',
    900: 'hsl(0, 0%, 100%)',
    950: 'hsl(0, 0%, 100%)',
  },
  default: {
    25: '#FCFCFD',
    50: '#F8FAFC',
    100: '#EEF2F6',
    200: '#E3E8EF',
    300: '#CDD5DF',
    400: '#9AA4B2',
    500: '#697586',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
  },
  primary: {
    25: '#FBFDFF',
    50: '#ECF2FF',
    100: '#D5E3FF',
    200: '#B2D9FF',
    300: '#76ACFF',
    400: '#0088FF',
    500: '#0076DF',
    600: '#005EB3',
    700: '#004689',
    800: '#003061',
    900: '#001B3C',
    DEFAULT: '#0088FF',
    foreground: 'hsl(0, 0%, 100%)',
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
    DEFAULT: '#32D583',
    foreground: 'hsl(0, 0%, 0%)',
  },
  danger: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
    DEFAULT: '#F97066',
    foreground: 'hsl(0, 0%, 100%)',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
    DEFAULT: '#FDB022',
    foreground: 'hsl(0, 0%, 0%)',
  },
};
