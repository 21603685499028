import {createSlice} from '@reduxjs/toolkit';
import {getLeads} from "../actions/leadsActions.js";
import isEqual from 'lodash.isequal';

const defaultParams = {
    use_pagination: true,
    rows_per_page: 15,
    download_leads: true,
    from_date: "",
    product_ids: [],
    task_category_ids: [],
    lead_status_ids: [],
    claim_status_ids: [],
    lead_status_id: "",
    filter_type: 'Active',
    name: "",
    page: 1,
};

const initialState = {
    data: {},
    searchParams: defaultParams,
    defaultParams,
    filterParams: defaultParams,
    infiniteIsOn: false,
    statuses: {},
    claimStatus : {},
    max_pages: 1
}

const leadsSlice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        resetPage(state){
            state.searchParams.page = 1;
        },
        incrementPage(state){
            state.searchParams.page = state.searchParams.page + 1;
        },
        updateSearchParam(state, action){
            const key = action.payload.key;
            const value = action.payload.value;
            state.searchParams[key] = value;
        },
        updateSearchParams(state, action){
            let newParams = action.payload;
            state.searchParams = {...state.searchParams, ...newParams};
        },
        resetSearchParams(state){
            state.searchParams = defaultParams;
        },
        updateFilterParam(state, action){
            const key = action.payload.key;
            const value = action.payload.value;
            state.filterParams[key] = value;
        },
        resetFilterParams(state){
            state.filterParams = defaultParams;
        },
        updateStatuses(state, action){
            const newStatuses = action.payload;
            if(!isEqual(newStatuses, state.statuses)) state.statuses = action.payload;
        },
        updateClaimStatuses(state, action){
            const newClaimReasons = action.payload;
            state.claimStatus = action.payload;//todo rm
            //if(!isEqual(newClaimReasons, state.claimStatus)) state.claimStatus = action.payload;
        },
        removeLead(state, action) {
            let newData = Object.values(state.data).filter(lead => {
                return lead.id !== action.payload
            })
            state.data = {};
            Object.assign(state.data, newData);
        },
        updateLeadStatus(state, action) {
            let {id, newValue} = action.payload;
            let newData = Object.values(state.data).map(lead => {
                return lead.id === id ? {...lead, lead_status: newValue} : lead
            })
            state.data = {};
            Object.assign(state.data, newData);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getLeads.fulfilled, (state, action) => {
                if(!!action.payload){
                    const response = action.payload;
                    const {data, pager} = response.data;
                    state.infiniteIsOn = response.data.pager.page < response.data.pager.max_pages;
                    if(state.searchParams.page > 1) {
                        state.data = {...state.data, ...data}
                    }else{
                        state.data = data;
                    }
                    state.max_pages = !!data && pager.max_pages;
                }
            })
    }
})

export const {resetPage, incrementPage, updateSearchParams, updateSearchParam, resetSearchParams,
    updateFilterParam, resetFilterParams, updateStatuses, updateClaimStatuses, removeLead, updateLeadStatus} = leadsSlice.actions;

export default leadsSlice.reducer;