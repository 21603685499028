import leadsReducer from '/resources/JS/modules/redux/slices/leadsSlice.js';
import loginReducer from '/resources/JS/modules/redux/slices/loginSlice.js';
import appStatusReducer from '/resources/JS/modules/redux/slices/appStatusSlice.js';
import {configureStore, combineReducers} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'


const leadsReducerPersist = persistReducer({
    key: "leads11",
    storage,
    whitelist: ["filterParams"],
  },
  leadsReducer
);

const rootReducer = combineReducers({
  leads: leadsReducerPersist,
  login: loginReducer,
  appStatus: appStatusReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActionPaths: ['payload.config', 'payload.request', 'payload.headers', 'register' , 'rehydrate'] //todo look into payload.headers
            }
        })
});

export const persistor = persistStore(store)

export default store;
