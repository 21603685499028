import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ValidForm from "/resources/JSX/general/validation/ValidForm";
import ValidTextField from "/resources/JSX/general/validation/ValidTextField";
import { loginConstants } from "/resources/JS/constants/LoginConstants";
import Reaptcha from "reaptcha";
import { useSelector } from "react-redux";
import { mixpanelService } from "/resources/JS/modules/tracking/MixpanelService";
import { useScreen } from "/resources/JSX/hooks/useScreen";
import Spinner from "../../general/spinner/Spinner";
import { Icon } from "@mui/material";

const LoginTemplate = (props) => {
  const {
    title,
    actions,
    handleSubmit,
    className = '',
    mainText = '',
    btnText = '',
    btnDisabled = false,
    includeEmail = false,
    includePassword = false,
    initValue = "",
    allowUserName = false,
    includeRecaptcha = false,
    formContent,
    showBackLink = false,
    mixpanel = {},
    formError = ''
  } = props;

  const { isSavingRequest } = useSelector((state) => state.appStatus);
  const { HOME } = loginConstants.routes;
  const [token, setToken] = useState("");
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [password, setPassword] = useState("");
  const { linkRequestCount } = useSelector((state) => state.login);
  const { RECAPTCHA_SITE_KEY, RESEND_LINK_MAX } = loginConstants.general;
  const { page, component, target } = mixpanel;
  const [email, setEmail] = useState(initValue);
  const { isLargeScreen } = useScreen();
  const ref = useRef(null);

  useEffect(() => {
    !isLargeScreen && scaleRecaptcha();
  }, []);

  const trackBackClick = () => {
    mixpanelService.trackClick("Back to login", {
      component,
      ...(email && { email }),
    });
  };

  const trackBtnClick = () => {
    mixpanelService.trackClick(target, { component, ...(email && { email }) });
  };

  const renderStartAdornment = (className) => {
    return <Icon className={className}></Icon>
  }

  useEffect(() => {
    if (!!showRecaptcha) {
      mixpanelService.trackRecaptchaDisplayed({
        component,
        ...(email && { email }),
      });
    }
  }, [showRecaptcha]);

  const onClick = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (includeRecaptcha) {
      setShowRecaptcha(linkRequestCount >= RESEND_LINK_MAX);
      if (!(linkRequestCount >= RESEND_LINK_MAX) || !!token) {
        try {
          await handleSubmit({ email, password });
        } finally {
          setToken("");
          setShowRecaptcha(false);
        }
      }
    } else {
      await handleSubmit({ email, password });
    }
  };

  const button = (
    <Button
      type="submit"
      variant="contained"
      disableRipple={true}
      disabled={btnDisabled || (showRecaptcha && !token)}
      form={"formId"}
      className="submit-button"
    >
      {" "}
      {btnText}{" "}
    </Button>
  );

  const recaptcha = (
      <Reaptcha
          sitekey={RECAPTCHA_SITE_KEY}
          onVerify={(newToken) => setToken(newToken)}
      />
  );

  const scaleRecaptcha = () => {
    let containerWidth =
        window.innerWidth < 768
            ? ref?.current?.offsetWidth - 30
            : ref?.current?.offsetWidth - 76;
    let reCaptchaWidth = 304;
    let recaptchaCollection = document.getElementsByClassName("g-recaptcha");
    let captchaScale = containerWidth / reCaptchaWidth;
    for (const r of recaptchaCollection) {
      r.style.transform = "scale(" + captchaScale + ")";
    }
  };

  return (
      <>
        {isSavingRequest && <Spinner displayBackdrop={true} />}
        <Card ref={ref}>
          <Typography variant="h4" component="div">
            {title}
          </Typography>
          <CardContent>
            <Box className={className}>
              {!!mainText && (
                  <Typography variant="body1" gutterBottom>
                    {mainText}
                  </Typography>
              )}
              <ValidForm
                  style={{ position: "relative" }}
                  onSubmit={onClick}
                  formId={"formId"}
                  preValidation={trackBtnClick}
                  shouldFocusError={true}
                  formError={formError}
              >
                {!!includeEmail && (
                    <ValidTextField
                        type={"text"}
                        name={"email"}
                        validateTypes={["required", !allowUserName && 'email']}
                        placeholder={`Enter your email ${allowUserName ? 'or username' : ''}`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        showErrorIcon={true}
                        className="form-control"
                        renderStartAdornment={() => renderStartAdornment("icon-email")}
                    />
                )}
                {!!includePassword && (
                    <ValidTextField
                        type={"password"}
                        name={"password"}
                        validateTypes={["required", "password"]}
                        placeholder={"Enter your password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        showErrorIcon={true}
                        className="form-control"
                        renderStartAdornment={() => renderStartAdornment("icon-password")}
                    />
                )}
                {!!formContent && formContent}
                {!!showRecaptcha && recaptcha}
                {button}
              </ValidForm>
              {showBackLink && (
                  <Link to={HOME} className="back" onClick={trackBackClick}>
                    Back to login
                  </Link>
              )}
            </Box>
          </CardContent>
          {actions && (
              <>
                <Divider />
                <CardActions>{actions}</CardActions>
              </>
          )}
        </Card>
      </>
  );
};
export default LoginTemplate;
