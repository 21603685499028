export const text = {
  'xs': {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem',
  },
  'sm': {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem',
  },
  'base': {
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem',
  },
  'lg': {
    fontSize: '1.125rem', // 18px
    lineHeight: '1.75rem',
  },
  'xl': {
    fontSize: '1.25rem', // 20px
    lineHeight: '1.75rem',
  },
  '2xl': {
    fontSize: '1.5rem', // 24px
    lineHeight: '2rem',
  },
  '3xl': {
    fontSize: '1.875rem', // 30px
    lineHeight: '2.25rem',
  },
  '4xl': {
    fontSize: '2.25rem', // 36px
    lineHeight: '2.5rem',
  },
  '5xl': {
    fontSize: '3rem', // 48px
    lineHeight: 'auto',
  },
  '6xl': {
    fontSize: '3.75rem', // 60px
    lineHeight: '1',
  },
  '7xl': {
    fontSize: '4.5rem', // 72px
    lineHeight: '1',
  },
  '8xl': {
    fontSize: '6rem', // 96px
    lineHeight: '1',
  },
  '9xl': {
    fontSize: '8rem', // 128px
    lineHeight: '1',
  },
};
