import {
    setIsSavingRequest,
    setIsLoading,
    setIsFetchingData
} from '../actions/appStatusActions.js';
import {createSlice} from '@reduxjs/toolkit';
import {getLeads} from "../actions/leadsActions.js";

const initialState = {
    isSavingRequest: false,
    isLoading: false,
    isFetchingData: false
}

const appStatusSlice = createSlice({
    name: 'appStatus',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(setIsSavingRequest, (state, action) =>{
                state.isSavingRequest = action.payload;
            })
            .addCase(setIsLoading, (state, action) => {
                state.isLoading = action.payload;
            })
            .addCase(setIsFetchingData, (state, action) => {
                state.isFetchingData = action.payload;
            })
            .addCase(getLeads.fulfilled, (state)=>{
                state.isSavingRequest = false;
                state.isLoading = false;
            })
            .addCase(getLeads.rejected, (state)=>{
                state.isSavingRequest = false;
                state.isLoading = false;
            })
    }
})

export default appStatusSlice.reducer;