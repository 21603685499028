export const loginConstants = {
    general: {
        RECAPTCHA_SITE_KEY: window.CHECKBOX_RECAPTCHA_SITE_KEY,
        INVALID_EMAIL_REASON: "Invalid email",
        COUNTER_VAL: 60,
        RESEND_LINK_MAX: 6,
        EXPIRE_STATE_MS: 900000,
        REDIRECT_STATUS: 301
    },
    routes: {
        HOME: '/',
        HO_LOGIN: '/homeowner-login',
        CO_LOGIN: '/login',
        GENERATE_LEADS: '/generate-leads',

        EMAIL_SENT_HASH: '/email-sent',
        EMAIL_NOT_EXIST_HASH: '/email-not-exist',
        FORGOT_PASSWORD_HASH: '/forgot-password'
    }
}
