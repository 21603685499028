import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    coEmail: '',
    hoEmail: '',
    linkRequestCount: 0,
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setHoEmail(state, action){
            state.hoEmail = action.payload;
        },
        setCoEmail(state, action){
            state.coEmail = action.payload;
        },
        increaseLinkRequestCount(state){
            state.linkRequestCount +=1;
        },
        resetHoEmail(state, action){
            state.hoEmail = initialState.hoEmail;
        },
        resetCoEmail(state, action){
            state.coEmail = initialState.coEmail;
        },
        reset(){
            return initialState;
        }
    }
})

export const {setCoEmail, setHoEmail, increaseLinkRequestCount,
    resetHoEmail, reset} = loginSlice.actions;

export default loginSlice.reducer;