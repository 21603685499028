import { theme as systemDesignTheme } from '../../design-system';

export const theme = {
  customScroll: `
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 5px;
      margin-left: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 5px;
    }
  `,
  media: {
    mobile: '@media (max-width: 768px)',
    tablet: '@media (max-width: 1000px)',
    desktop: '@media (min-width: 769px)',
    smallDesktop: '@media (max-width: 1600px)',
  },
  palette: {
    darkFont: '#0B0B0C',
    font: '#1D2939',
    border: '#f1f1f1',
    blue: '#0088FF',
    lightBlue: '#f3faff',
  },
  borderRadius: '12px',
  text: systemDesignTheme.text,
  colors: systemDesignTheme.colors,
};
