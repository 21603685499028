import {apiService} from "./ApiService.js";

class LoginApi {

    confirmEmail(email){
        let url = `homeowner/confirm_email`
        return  apiService.request('get', url, {email});
    }

    loginAsCO(text, remember_me){
        const options = {
            headers: {Authorization: "Basic " + text},
            shouldDisplayError: false
        }
        return apiService.request('post', 'token', {remember_me}, options, 'v3')
    }

    retrievePassword(email){
        let url = 'password_retrieval'
        return  apiService.request('post', url, {email}, {shouldDisplayError: false});
    }
}

export let loginApi = new LoginApi();
